import React, { useState } from "react";
import "../style/beta.scss";
import Popup from "./Popup";

const Beta = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleMarqueeClick = () => {
    setShowPopup(true);
    document.body.style.overflow = "hidden";
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    document.body.style.overflow = "auto";
  };
  const handleMouseEnterMarquee = (event) => {
    event.target.stop(); // Stop marquee animation
  };

  const handleMouseLeaveMarquee = (event) => {
    event.target.start(); // Resume marquee animation
  };
  return (
    <div className="box">
      <div className="panel beta">
        <marquee
          direction="left , right"
          behavior="alternate"
          onClick={handleMarqueeClick}
          onMouseEnter={handleMouseEnterMarquee}
          onMouseLeave={handleMouseLeaveMarquee}
        >
          <span className="star">✦</span> Join our beta{" "}
          <span className="star">✦</span> Join our beta{" "}
          <span className="star">✦</span> Join our beta{" "}
          <span className="star">✦</span>
        </marquee>
        {showPopup && <Popup onClose={handleClosePopup} />}
      </div>
    </div>
  );
};

export default Beta;
