import { Link } from "react-router-dom";
import { BrandLogo } from "../images/svj";
import "../style/header.scss";
const Header = () => {
  return (
    <div className="Header">
      <div>
        <Link to="/">
          <BrandLogo />
        </Link>
      </div>
      <button class="join_beta_btn">Join Beta</button>
    </div>
  );
};

export default Header;
