import Sidebar from "../images/Group 402515323.svg";
import banner from "../images/Rectangle 38.png";
const PrivacyPolicy = () => {
  return (
    <div className="dark-bg privacy-policy-container">
      <div className="sidebar_svg first_pattern_image">
        <img src={Sidebar} alt="Solid Dark green hexagonal pattern, resembling a molecular structure." />
      </div>
      <div>
        <h1> Privacy Policy</h1>
        <div className="last-updated">
          <p>-</p>
          <p>Last Updated 26 Apr,2024</p>
        </div>
        <div className="banner_image">
          <img src={banner} alt="" />
        </div>
        <div className="policy">
          <ol>
            <div className="policy-description">
              <h3>
                <li className="heading">Introduction</li>
              </h3>
              <p>
                Welcome to Fragmatic Technologies' Privacy Policy.
                <br></br>
                <br></br>
                At Fragmatic, we value your privacy and are committed to
                protecting your personal data. This privacy policy aims to
                inform you about how we handle your personal data when you visit
                our website and use our services. It also outlines your privacy
                rights and how the law protects you. Data protection law,
                including the General Data Protection Regulation (GDPR) and the
                California Consumer Privacy Act (CCPA), may apply to the
                processing of your personal data.
              </p>
            </div>
            <div className="policy-description">
              <h3>
                <li className="heading"> Purpose of this Policy</li>
              </h3>
              <p>
                This privacy policy provides information on how Fragmatic
                Technologies collects and processes your personal data,
                particularly when you interact with our website or use our
                services. We strive to ensure that you are fully aware of how
                and why we use your data. Please read this policy alongside any
                other privacy notices we may provide on specific occasions.
              </p>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading">Controller</li>
              </h3>
              <p>
                Fragmatic Technologies is the data controller responsible for
                your personal data.
              </p>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading">Contact Details</li>
              </h3>
              <p>
                For any questions regarding this privacy policy or to exercise
                your legal rights, please contact us at the following:
              </p>

              <ul>
                <li>
                  {" "}
                  Full Name of Legal Entity: Fragmatic Technologies Pvt. Ltd.
                </li>
                <li> Data Protection Manager: Vidhata Nand</li>
                <li> Email Address: v@fragmatic.io</li>
              </ul>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading"> Third-Party Links</li>
              </h3>
              <p>
                Our website may contain links to third-party websites, plugins,
                or applications. Clicking on these links may allow third parties
                to collect or share data about you. Fragmatic Technologies does
                not control these third-party websites and is not responsible
                for their privacy policies. We recommend reviewing the privacy
                policies of any website you visit after leaving our site.
              </p>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading">Data Collected</li>
              </h3>
              <p>
                {" "}
                We may collect various types of personal data, including but not
                limited to:
              </p>
              <ul>
                <li>
                  {" "}
                  Identity Data: First name, last name, Company name, job title
                  and other demographic data
                </li>{" "}
                <li>Contact Data: Email address, telephone numbers</li>
                <li>
                  Technical Data: IP address, browser type, device information
                </li>
                <li>
                  {" "}
                  Financial Data: This category comprises your bank account and
                  payment card details.
                </li>
                <li>
                  {" "}
                  Transaction Data: These are details about payments to and from
                  you, along with other information regarding products and
                  services you have purchased from us.
                </li>
                <li> Usage Data: Information about how you use our website</li>
                <li>
                  Marketing and Communications Data: Preferences for receiving
                  marketing materials
                </li>
                <li>
                  Special Categories of Personal Data: Fragmatic Technologies
                  does not collect any Special Categories of Personal Data (as
                  defined under GDPR) such as race, religion, health data, etc.
                </li>
              </ul>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading"> How is Personal Data Collected</li>
              </h3>
              <p>We gather your information through several methods: </p>
              <ul>
                <li>
                  Directly from You: This includes details you provide when
                  creating an account, using our services, requesting marketing
                  materials, or offering feedback. This may involve your name,
                  contact information, and potentially financial data.
                </li>
                <li>
                  {" "}
                  Automatic Means: As you interact with our website, we might
                  automatically collect technical data about your device,
                  browsing habits, and patterns. This is often done using
                  cookies, server logs, and similar technologies. We may also
                  receive technical data from other websites using our cookies.
                </li>
                <li>
                  {" "}
                  Third-Party Sources: In some instances, we may obtain your
                  information from trusted third parties and public sources like
                  analytics providers, and contact/identity info from public
                  sources or third parties (data brokers, sign-in services,
                  payment processors).
                </li>
              </ul>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading"> Use of Personal Data</li>
              </h3>
              <p>We may use your personal data for the following purposes:</p>
              <ul>
                <li>Sending newsletters about our products and services.</li>
                <li>Sending promotional materials related to our offerings.</li>
                <li>
                  Providing information about our products, services, terms, and
                  discounts.
                </li>
              </ul>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading">Marketing and Cookies</li>
              </h3>
              <p>
                We may use cookies and similar technologies to improve our
                services and provide relevant experiences. You can manage your
                cookie preferences through your browser settings. If you wish to
                opt-out of marketing communications, please contact us using the
                details provided in this policy.
              </p>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading"> Data Sharing</li>
              </h3>
              <p>We may share your personal data with:</p>
              <ul>
                {" "}
                <li>
                  Internal Third Parties: Within Fragmatic Technologies for
                  business operations.
                </li>
                <li>
                  External Third Parties: Service providers who assist in IT
                  services, marketing, and other functions.
                </li>
                <li>
                  {" "}
                  Business Transfers: In the event of a merger, acquisition, or
                  sale of assets, personal data may be transferred.
                </li>
              </ul>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading">International Transfers</li>
              </h3>
              <p>
                Your personal data may be transferred and processed outside the
                country where you reside. We ensure adequate protection by using
                appropriate safeguards, such as standard contractual clauses or
                Privacy Shield certification.
              </p>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading">Data Security</li>
              </h3>
              <p>
                We have implemented security measures to protect your personal
                data from unauthorized access, disclosure, alteration, or
                destruction. Access to your data is limited to authorized
                personnel who are bound by confidentiality obligations.
              </p>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading"> Data Retention</li>
              </h3>
              <p>
                We retain your personal data for as long as necessary to fulfill
                the purposes for which it was collected, including legal,
                accounting, or reporting requirements. We may anonymize data for
                research or statistical purposes.
              </p>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading"> Legal Rights</li>
              </h3>
              <p>
                Under applicable data protection laws, you have the following
                rights regarding your personal data:
              </p>
              <ul>
                <li> Access: Request access to your personal data.</li>
                <li>
                  {" "}
                  Correction: Request correction of any incomplete or inaccurate
                  data.
                </li>
                <li>Erasure: Request deletion of your personal data.</li>
                <li> Object: Object to processing of your personal data.</li>
                <li>
                  {" "}
                  Restriction: Request restriction of processing your personal
                  data.
                </li>
                <li> Portability: Request transfer of your personal data.</li>
                <li>
                  Withdrawal of Consent: Withdraw any previously given consent.
                </li>
              </ul>
              <p>
                Please note that we may need to verify your identity before
                responding to such requests. To exercise these rights or for any
                inquiries, please contact us using the details provided.
              </p>
            </div>
          </ol>
          <div className="policy-description">
            <h3 className="heading">Conclusion</h3>
            <p>
              Thank you for taking the time to read Fragmatic Technologies'
              Privacy Policy. We are committed to protecting your privacy and
              ensuring transparency in how we handle your personal data. If you
              have any questions or concerns, please do not hesitate to contact
              us.
            </p>
          </div>
        </div>
      </div>
      <div className="sidebar_svg second_pattern_image">
        <img src={Sidebar} alt="animation" />
      </div>
      <div className="sidebar_svg third_pattern_image">
        <img src={Sidebar} alt="animation" />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
