import Footer from "../Components/Footer";
import Header from "../Components/Header";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import "../style/privacyPolicy.scss";

const PrivacyPolicyPage = () => {
  return (
    <div className="privacy-policy-main">
      <Header/>
      <PrivacyPolicy />
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
