import { useLayoutEffect } from "react";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import "../style/cardone.scss";
import calenderMobile from "../images/Calendar.png";
import { Calender } from "../images/svj";
import Testsvg from "./Testsvg";
import { LineChart } from "../images/svj";
import { DropDown } from "../images/svj.js";
import { GraphMobile } from "../images/svj.js";
gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

const CardOne = () => {
  useLayoutEffect(() => {
    gsap.defaults({ ease: "none" });

    const pulses = gsap;
    const main = gsap
      .timeline({
        defaults: { duration: 1 },
        scrollTrigger: {
          trigger: "#svg",
          start: "top center",
          end: () =>
            "+=" + document.querySelector(".theLine")?.getTotalLength() - 100,
          scrub: true,
          markers: false,
        },
      })
      .to(".ball01", { duration: 0.00001, autoAlpha: 1 })
      .from(".theLine", { drawSVG: 0 }, 0)
      .to(
        ".ball01",
        {
          motionPath: {
            path: ".theLine",
            align: ".theLine",
            alignOrigin: [0.5, 0.5],
          },
        },
        0
      )
      .add(pulses, 0);
  });

  return (
    <div className="card_one">
      <div className="card_one_box">
        <div className="card_one_header">
          <span className="Card_one_left">
            <Calender className="svg_icon" />
            <img src={calenderMobile} alt="Red gradient flip-style calendar icon with two rings at the top and two horizontal lines indicating dates." className="mobile_icon" />
          </span>
          <div className="card_one_right">
            <h6>Pipeline Impact</h6>
            <span className="select_box">
              <p>This Week</p>
              <DropDown />
            </span>
          </div>
        </div>
        <span className="card_percentage">
          <h5>+28%</h5>
        </span>
        <div className="img_box">
          <GraphMobile />
          <div className="img">
            <LineChart className="line_chart" />
            <Testsvg className="test_img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardOne;
