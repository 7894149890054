import React, { lazy } from "react";
import "../style/home.scss";

import Banner from "../Components/Banner"
import Stack from "../Components/Stack"
import Card from "../Components/Card"
import Beta from "../Components/Beta"
import Footer from "../Components/Footer"
import Navbar from "../Components/Navbar"
import CardOne from "../Components/CardOne"
import CardTwo from "../Components/CardTwo"
import CardThree from "../Components/CardThree"
import RightCardOne from "../Components/RightCardOne"
import LifeSideCard from "../Components/LeftSideCard"
import RightTwoCard from "../Components/RightTwoCard"
const Home = () => {
  return (
    <>
      <div className="home">
        <Navbar />
        <Banner
          heading={
            "We are the world’s 2nd best web personalization SaaS for B2B businesses"
          }
          para={"Get private beta access"}
          close={false}
        />
        <Card />
        <div className="home-card">
          <RightCardOne />
          <CardOne />
          <CardTwo />
          <CardThree />
          <RightTwoCard />
          <LifeSideCard />
        </div>
        <Stack />
        <Beta />
        <Footer />
      </div>
    </>
  );
};

export default Home;
