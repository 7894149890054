import Sidebar from "../images/Group 402515323.svg";
import banner from "../images/terms-banner.jpg";
const PrivacyPolicy = () => {
  return (
    <div className="dark-bg privacy-policy-container">
      <div className="sidebar_svg first_pattern_image">
        <img src={Sidebar} alt="Solid Dark green hexagonal pattern, resembling a molecular structure." />
      </div>
      <div>
        <h1> Terms of Services</h1>
        <div className="last-updated">
          <p>-</p>
          <p>Last Updated 26 Apr,2024</p>
        </div>
        <div className="banner_image">
          <img src={banner} alt="" height="310px" width="1180px" />
        </div>
        <div className="policy">
          <ol>
            <div className="policy-description">
              <h3>
                <li className="heading">General</li>
              </h3>
              <p>
                Welcome to Fragmatic's Terms of Service.
                <br></br>
                <br></br>
                Fragmatic Technologies (hereinafter, "Fragmatic," "we," "us," or
                "our'') offers a web personalization Software-as-a-Service
                (SaaS) platform designed for B2B businesses. By accessing or
                using Fragmatic's services, including our website and software
                (collectively, the "Service"), you agree to these Terms of
                Service ("Terms'') as a legally binding agreement between you
                and Fragmatic. These Terms also include our Privacy Policy and
                any other policies referenced herein.
                <br></br>
                <br></br>
                These Terms are an electronic record in accordance with the
                Information Technology (Intermediaries guidelines) Rules, 2011,
                and do not require physical or digital signatures. Please read
                these Terms carefully before using our Service. By accessing or
                using the Service, you acknowledge that you have read,
                understood, and agree to be bound by these Terms.
              </p>
            </div>
            <div className="policy-description">
              <h3>
                <li className="heading"> Registration</li>
              </h3>
              <p>
                To access and use Fragmatic's services, you may need to create
                an account. Registration may require providing basic
                information, and you agree to keep your account details accurate
                and up-to-date. You are responsible for maintaining the
                confidentiality of your account and are liable for all
                activities conducted through your account.
              </p>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading">Eligibility</li>
              </h3>
              <p>
                By using Fragmatic's Service, you affirm that you are legally
                eligible to enter into a binding contract and comply with these
                Terms. Individuals under the age of 18 or "incompetent to
                contract" as per applicable laws are not permitted to use
                Fragmatic's Service.
              </p>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading">License and Access</li>
              </h3>
              <p>
                Fragmatic grants you a limited, non-exclusive, non-transferable
                license to access and use our Service. This license does not
                permit you to modify, reproduce, distribute, or resell our
                software or any part of it. Unauthorized use of the Service,
                including data mining, robots, or similar tools, is prohibited.
              </p>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading"> User Obligations</li>
              </h3>
              <p>As a user of Fragmatic, you agree to:</p>
              <ul>
                <li>
                  {" "}
                  Not to cut, copy, distribute, modify, recreate, reverse
                  engineer, distribute, disseminate, post, publish, or create
                  derivative works from, transfer, or sell any information or
                  software obtained from the Software without prior permission.
                  Limited use may be allowed with our explicit consent. However,
                  unlimited or wholesale reproduction, copying of content for
                  commercial or non-commercial purposes, or unwarranted
                  modification of data within the Software's content is strictly
                  prohibited.
                </li>{" "}
                <li>
                  Not to access the Software or its materials or Services by any
                  means other than through the interface provided. You agree not
                  to use deep-links, robots, spiders, or similar automatic
                  devices, programs, or methodologies to access, acquire, copy,
                  or monitor any portion of the Software or Content. You may not
                  circumvent the navigational structure or presentation of the
                  Software to obtain or attempt to obtain materials or
                  information through unauthorized means. By using the Software,
                  you may encounter content from other users that you may find
                  offensive; in such cases, you may report the content.c. When
                  posting or uploading data/information on the Software, you
                  must ensure that the material is not offensive and complies
                  with applicable laws. <br></br> You also agree:
                </li>
                <li>
                  Not to abuse, harass, threaten, defame, or violate the legal
                  rights of others.
                </li>
                <li>
                  {" "}
                  Not to engage in any activity that disrupts access to the
                  Software or its Services.
                </li>
                <li>
                  {" "}
                  Not to impersonate any person or entity or misrepresent your
                  affiliation with any entity.
                </li>
                <li>
                  Not to publish, post, or disseminate grossly harmful,
                  harassing, defamatory, obscene, pornographic, or otherwise
                  objectionable content.
                </li>
                <li>
                  Not to post any files that infringe on the copyright, patent,
                  or trademark of other legal entities.
                </li>
                <li>
                  Not to upload or distribute files containing viruses,
                  corrupted files, or similar software that may damage the
                  operation of the Software.
                </li>
                <li>
                  Not to download any files posted by another user that you
                  know, or reasonably should know, cannot be legally
                  distributed.
                </li>
                <li>
                  Not to probe, scan, or test the vulnerability of the Software
                  or any network connected to it, or breach its security
                  measures.
                </li>
                <li>
                  Not to disrupt or interfere with the security of the Software,
                  system resources, accounts, passwords, servers, or networks
                  connected to the Software.
                </li>
                <li>
                  Not to use the Software or its content for any unlawful or
                  prohibited purpose.
                </li>
                <li>
                  Not to violate any code of conduct or guidelines applicable to
                  any particular Service.
                </li>
                <li>
                  Not to violate any applicable laws or regulations within or
                  outside your jurisdiction.
                </li>
                <li>
                  Not to threaten the unity, integrity, defense, security, or
                  sovereignty of any nation.
                </li>
                <li>
                  Not to publish false, inaccurate, or misleading information.
                </li>
                <li>
                  Not to offer, attempt to offer, trade, or attempt to trade in
                  any item prohibited or restricted under applicable laws or
                  regulations.
                </li>
                <li>
                  Not to create liability for Fragmatic or cause it to lose the
                  services of its internet service providers or other suppliers.
                </li>
                <li>
                  You shall not engage in advertising, solicitation, or
                  transmission of chain letters or unsolicited commercial or
                  junk email to other users via the Software. It is a violation
                  of these Terms to use information obtained from the Software
                  to harass, abuse, or harm another person, or to contact,
                  advertise to, solicit, or sell to another person without
                  Fragmatic's explicit consent.
                </li>
                <li>
                  Fragmatic reserves the right to disclose any information about
                  you to law enforcement or government officials if necessary,
                  in its sole discretion, to investigate and/or resolve possible
                  crimes or violations of these Terms. We may restrict the
                  number of messages or emails you can send to other users and
                  have the right to remove or edit any content that violates
                  these Terms.
                </li>
                <li>
                  Fragmatic has no obligation to monitor materials posted on the
                  Software but reserves the right to remove or edit any content
                  that violates applicable laws or these Terms. Fragmatic
                  assumes no responsibility or liability for any Content posted
                  by users or for any claims, damages, or losses resulting from
                  the use of such Content on the Software.
                </li>
                <li>
                  You represent and warrant that you have all necessary rights
                  to any content you provide, and such content shall not
                  infringe on any proprietary or other rights of third parties
                  or contain any unlawful information.
                </li>
                <li>
                  By using Fragmatic's Software, you agree to abide by these
                  User Obligations. Failure to comply may result in the
                  suspension or termination of your account and access to the
                  Service. If you have any questions or concerns about these
                  User Obligations, please contact us at product@fragmatic.io 
                </li>
              </ul>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading">Fees, Invoicing, and Payment</li>
              </h3>
              <h4> Fees</h4>
              <ul>
                <li>
                  Fees are determined by the Services subscriptions purchased,
                  not the actual usage of the Service.
                </li>
                <li>
                  Payment obligations are binding, and fees paid are
                  non-refundable, even in the case of a downgrade of
                  subscription under an active Order Form.
                </li>
                <li>
                  All applicable taxes, duties, or government levies are
                  additional to the fees and expenses specified under the Order
                  Form. Users are responsible for timely payment of such taxes,
                  duties, or government levies.
                </li>
              </ul>
              <br></br>
              <h4> Invoicing and Payment Arrangements</h4>
              <p>
                Fragmatic will provide an electronic invoice to the Customer for
                payment, which can be viewed and printed from the Customer's
                Account. Invoicing will occur upon execution of this Agreement
                at the fee specified in the Order Form on the specified date.
                Recurring fees will be billed as per the agreed frequency in the
                Order Form. Refunds or adjustments for plan downgrades,
                upgrades, or elimination of plan features within the current
                billing period will not be issued. Invoices/payments are
                considered final and accepted by the Customer unless disputed or
                clarification is sought before subscribing to the Service.
              </p>
              <br></br>
              <h4> Payment by Credit Card/Wire Transfer/Cheque</h4>
              <p>
                Customers paying by credit card must ensure their contact,
                credit card (if applicable), and billing information is current
                and updated on the Dashboard. Fragmatic is authorized to charge
                the Customer's credit card or bank account for all fees payable
                at the beginning of the Term and subsequent billing periods,
                including upgrades. Fragmatic may use third parties for payment
                processing and invoicing, with the Customer's consent to
                disclose payment information to such third parties.
              </p>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading"> Intellectual Property</li>
              </h3>
              <p>
                Fragmatic retains all rights to the intellectual property
                associated with the Service, including trademarks, logos, and
                software. You may not use Fragmatic's intellectual property
                without explicit permission.
              </p>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading"> Disclaimer of Warranties</li>
              </h3>
              <p>
                Fragmatic provides the Service "as is" and does not make any
                warranties regarding its accuracy, reliability, or suitability
                for your specific needs. We do not guarantee uninterrupted,
                secure, or error-free operation of the Service.
              </p>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading">Limitation of Liability</li>
              </h3>
              <p>
                In no event shall Fragmatic be liable for any indirect,
                incidental, special, consequential, or punitive damages arising
                from your use of the Service. Our total liability, whether in
                contract, warranty, tort (including negligence), or otherwise,
                shall not exceed the amount you paid to us for the Service.
              </p>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading"> Termination</li>
              </h3>
              <p>
                You may terminate your account with Fragmatic at any time by
                following the specified procedures. We reserve the right to
                suspend or terminate your account for violations of these Terms
                or for any other reason deemed appropriate.
              </p>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading">
                  Governing Law and Dispute Resolution
                </li>
              </h3>
              <p>
                These Terms are governed by the laws of India without regard to
                its conflict of law principles. Any dispute arising out of or
                relating to these Terms or the Service shall be resolved through
                arbitration in accordance with the rules of Delhi International
                Arbitration Centre (Arbitration Proceedings) Rules, 2023 (“DIAC
                Rules”) to be held in New Delhi, India
              </p>
            </div>

            <div className="policy-description">
              <h3>
                <li className="heading">Miscellaneous</li>
              </h3>
              <p>
                Entire Agreement: These Terms constitute the entire agreement
                between you and Fragmatic regarding the Service and supersede
                any prior agreements or understandings.
              </p>
              <br></br>
              <p>
                Waiver: The failure to enforce any provision of these Terms
                shall not constitute a waiver of that provision.
              </p>
              <br></br>
              <p>
                Severability: If any provision of these Terms is found to be
                invalid or unenforceable, the remaining provisions shall remain
                in full force and effect.
              </p>
              <br></br>
              <p>
                Updates: Fragmatic reserves the right to update or modify these
                Terms at any time, and such changes will be effective upon
                posting on our website.
              </p>
              <br></br>
              <p>
                By using Fragmatic's Service, you agree to these Terms and any
                updates or modifications made by Fragmatic. If you have any
                questions or concerns about these Terms, please contact us at product@fragmatic.io
              </p>
              <br></br>
              <br></br>
              <p>
                Thank you for using Fragmatic's web personalization Service.
              </p>
              <br></br>
              <br></br>
              <p>Fragmatic Technologies </p>
              <br></br>
              <p>M-66C, Adani Samsara, Sector-60 Gurgaon Sadar Bazar Gurgaon</p>
              <br></br>
              <p>Haryana, India</p>
              <br></br>
              <br></br>
              <p>
                This Terms of Service document is a legally binding agreement
                between Fragmatic Technologies and users of its web
                personalization Software-as-a-Service (SaaS) platform. It
                outlines user obligations, intellectual property rights,
                limitations of liability, and more. It is designed to protect
                both Fragmatic and its users while providing clear guidelines
                for using the Service.
              </p>
            </div>
          </ol>
        </div>
      </div>
      <div className="sidebar_svg second_pattern_image">
        <img src={Sidebar} alt="Solid Dark green hexagonal pattern, resembling a molecular structure." />
      </div>
      <div className="sidebar_svg third_pattern_image">
        <img src={Sidebar} alt="Solid Dark green hexagonal pattern, resembling a molecular structure." />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
