import "../style/pageNotFound.scss";
import pattern from "../images/Group 402515323.svg";
import { Link } from "react-router-dom";
const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <div className="content">
        <h1>The requested page is unavailable.</h1>
        <section class="error-container">
          <span class="four">
            <span class="screen-reader-text">4</span>
          </span>
          <span class="zero">
            <span class="screen-reader-text">0</span>
          </span>
          <span class="four">
            <span class="screen-reader-text">4</span>
          </span>
        </section>
        <h3>
          No worries, you can head back to the home using the button below.{" "}
        </h3>
        <div class="link-container">
          <Link to="/" class="more-link">
            Go To Home
          </Link>
        </div>
      </div>
      <div className="sidebar_svg first_pattern_image">
        <img src={pattern} alt="Solid Dark green hexagonal pattern, resembling a molecular structure." />
      </div>
      <div className="sidebar_svg second_pattern_image">
        <img src={pattern} alt="Solid Dark green hexagonal pattern, resembling a molecular structure." />
      </div>
    </div>
  );
};

export default PageNotFound;
