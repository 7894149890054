import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions";
import Home from "../pages/Home";
import PageNotFound from "../pages/PageNotFound";
import { Navigate } from 'react-router-dom';

const routes = [
  {
    title: "Privacy-policy",
    route: "/privacy-policy",
    component: <PrivacyPolicy />,
    authenticated: false,
  },
  {
    title: "Terms and Conditions",
    route: "/terms",
    component: <TermsAndConditions />,
    authenticated: false,
  },
  {
    title: "Home",
    route: "/",
    component: <Home />,
    authenticated: false,
  },
  {
    title: "Are you Lost?",
    route: "/404",
    component: <PageNotFound />,
    authenticated: false,
  },
  {
    title: "Redirect...",
    route: "*",
    component: <Navigate to="/404" replace />,
    authenticated: false,
  },
];

export default routes;
