import React,{ useState } from "react";
import { FragmaticF } from "../images/svj";
import "../style/footer.scss";
import SidebarSvg from "./SidebarSvg";
import { Instagram, Linkedin, Twitter } from "../images/svj";
import Popup from "./Popup";

const Footer = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleMarqueeClick = () => {
    setShowPopup(true);
    document.body.style.overflow = "hidden";
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    document.body.style.overflow = "auto";
  };
  return (
    <>
      <div className="footer">
        <div className="footer_logo">
          <FragmaticF />
        </div>
        <div className="footer_box">
          <div className="footer_left">
            <div className="footer_menu">
              <ul>
                <li>
                  <span className="color">Company</span>
                </li>
                <li>
                  <a target="_blank" href="https://one.fragmatic.io/">
                    Fragmatic One
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://fragmatic.io/blog">
                    Blog
                  </a>
                </li>
                <li>
                  <span href="/contact" onClick={handleMarqueeClick}>
                    Contact
                  </span>
                </li>
                <li>
                  <a
                    href="https://twitter.com/i/flow/login?redirect_after_login=%2Ffragmatic_io"
                    target="_blank"
                    rel="nofollow"
                  >
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/fragmatic/"
                    target="_blank"
                    rel="nofollow"
                  >
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer_right">
            <h1>© 2024 Fragmatic. All rights reserved. </h1>
            <div className="footer_icon">
              <a
                href="https://www.linkedin.com/company/fragmatic/"
                target="_blank"
                rel="nofollow"
              >
                <Linkedin />
              </a>
              <a
                className="tweet"
                href="https://twitter.com/i/flow/login?redirect_after_login=%2Ffragmatic_io"
                target="_blank"
                rel="nofollow"
              >
                <Twitter />
              </a>
              <a
                href="https://instagram.com/fragmatic_io?igshid=MzRlODBiNWFlZA=="
                target="_blank"
                rel="nofollow"
              >
                <Instagram />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_svg">
        <SidebarSvg />
      </div>
      {showPopup && <Popup onClose={handleClosePopup} />}
    </>
  );
};

export default Footer;
