import Footer from "../Components/Footer";
import Header from "../Components/Header";
import TermsAndConditions from "../Components/TermsAndConditions";
import "../style/privacyPolicy.scss";

const TermsAndConditionsPage = () => {
  return (
    <div className="privacy-policy-main">
      <Header/>
      <TermsAndConditions />
      <Footer />
    </div>
  );
};

export default TermsAndConditionsPage;
