import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import routes from "./utils/routes";
import "./App.css";

function App() {
  const routeComponents = routes?.map(
    ({ route, component, authenticated }, key) => (
      <Route path={route} element={<>{component}</>} />
    )
  );

  return (
    <>
      <Router>
        <Routes>{routeComponents}</Routes>
      </Router>
    </>
  );
}

export default App;
