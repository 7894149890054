import React from "react";
import "../style/Navbar.scss";
import { FragmaticF } from "../images/svj";
import { useState, useEffect } from "react";

const Navbar = () => {
  const [notShow, setNotShow] = useState(true);

  const controlNavbar = () => {
    const scrollY = window.scrollY;
    if (scrollY > 150) {
      setNotShow(false);
      if (scrollY > 1295) {
        setNotShow(true);
      }
    } else {
      setNotShow(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  return (
    <>
      <div className={`active ${notShow && "hidden"}`}>
        <div className="navbar_logo">
          <FragmaticF className="svg_logo"/>
        </div>
      </div>
    </>
  );
};

export default Navbar;
