import { useState, useEffect } from "react";
// import { BrandLogo, CloseLogo } from "../images/svj";
import "../style/popup.scss";
import Banner from "./Banner";

const Popup = ({ onClose }) => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMediumScreen = screenSize < 992 && screenSize > 500;

  return (
    <div className="popupContainer">
      <Banner
        heading={"Get private beta access"}
        para={
          "We are currently in the process of fine-tuning Fragmatic and it would be great to have you onboard as part of that journey."
        }
        close={true}
        onClose={onClose}
      />
      <span
        className={`banner ${
          isMediumScreen ? "text_box text_box_mobile" : "text_box "
        }`}
      >
        <p className="paragraph text-up">
          By signing up, you agree to our Privacy Policy and Terms of Service
        </p>
      </span>
    </div>
  );
};

export default Popup;
